<template>
    <div>
        <v-card flat id="product_datatable">
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">Carrier Products</p>
                        <p class="pt-0 mt-0 ">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Products
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start" v-if="toggleFilters || showFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys">
                    <v-col cols="4">
                        <v-dialog v-model="dialog" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" class="mt-2">
                                    Divisions <span v-if="division_filter_count > 0">({{division_filter_count}})</span>
                                </v-btn>
                                <!-- <span v-for="division in user.all_divisions" :key="division.ID">
                                    <small>
                                        <span v-if="division.ActiveInd == '1'" class="mr-2">
                                            {{division.Division}}:Active
                                        </span>
                                        <span v-if="division.HiddenInd == '1'" class="mr-2">
                                            {{division.Division}}:Hidden
                                        </span>
                                    </small>
                                </span> -->
                            </template>
                            <v-card>
                                <v-card-title class="text-h5">
                                    Filter by Division
                                </v-card-title>
                                <v-card-text>
                                    <div v-if="user.all_divisions.length > 0">
                                        <v-row v-for="division in user.all_divisions" :key="division.ID" dense>
                                            <v-col cols="4" class="pt-4 pl-4">{{division.Division}}</v-col>
                                            <v-col cols="4">
                                                <v-checkbox label="Active" true-value="1" false-value="0" v-model="division.ActiveInd" />
                                            </v-col>
                                            <v-col cols="4">
                                                <v-checkbox label="Hidden" true-value="1" false-value="0" v-model="division.HiddenInd" />
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn color="green darken-1" text @click="dialog = false">
                                    Disagree
                                </v-btn> -->
                                    <v-btn color="green darken-1" text @click="dialog = false">
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </q-filters>
            </v-row>
            <v-row class="ml-5 mb-3">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-row>
            <QExpandableDataTable
                auto color="q_leads_1" md="3" lg="99999"
                :fixed-header="true" :mobile-breakpoint="2" :search="search" :headers="the_headers" :items="all_data" item-key="ID" 
                class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" 
                v-on:click:row="viewProduct"
            >
                <template v-slot:item.ProductCodes="{ item }">
                    <span :style="'white-space: normal'">
                        {{listProductCodes(item)}}
                    </span>
                </template>

                <template 
                    v-for="column in ['eAppInd', 'PaperAppInd', 'InstantDecisionInd', 'FlexiblePremiumPossibleInd', 'NY', 'CA']"
                    #[`item.${column}`]="{ item }"
                >
                    <v-icon :color="item[column] == '1' ? 'success' : 'red'" :key="column">
                        fas {{ item[column] == '1' ? 'fa-check' : 'fa-times' }}
                    </v-icon>
                </template>

                <template #[`item.ProductGuide`]="{ item }">
                    <v-icon @click.stop.prevent="clicked(item.ProductGuide, true)" color="success">fas fa-external-link-alt</v-icon>
                </template>

                <template v-slot:item.ID="{ item }">
                    <span class="nowrap">
                        <v-btn color="q_new_b_1 white--text  body-2" :to="{ name: 'ProductDetail', params: { product_id: item.ID} }" v-if="hasPermission('manage:Carriers')">Edit</v-btn>
                    </span>
                </template>

                <template #open-row-button="{ item }">
                    <v-btn color="q_new_b_1 white--text body-2" class="ml-4" :to="{ name: 'ProductDetail', params: { product_id: item.ID} }" v-if="hasPermission('manage:Carriers')">Edit</v-btn>
                </template>
            </QExpandableDataTable>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QExpandableDataTable from './QExpandableDataTable.vue'
import { debounce } from 'vue-debounce'

export default {
    name: "QProductDataTable",
    props: [],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'product',
            //use this to "preset" a filter
            filters: {}, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': [], //used to set the default sorting column
                'sortDesc': [true],
                'itemsPerPage': 20
            },
            expanded: [],
            available_product_filters: null,
            the_data: [], //this will hole the data fromt he server now that we aren't using vuex anymore.
            showFilters: false
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            let products = this.filterProductsByDivisionFilters(this.the_data)
            return products.filter(row => {

                const { Carrier, ProductSolution, ProductType, ProductSubtype } = this.filters
                if (Carrier != '-All-' && Carrier != row.CarrierID)
                    return false
                if (ProductSolution !== '-All-' && ProductSolution !== row.ProductSolution)
                    return false
                if (ProductType !== '-All-' && ProductType !== row.ProductType)
                    return false
                if (ProductSubtype !== '-All-' && ProductSubtype !== row.ProductSubtype)
                    return false

                return true
            })
        },
        filter_keys: function() {
            return ['Carrier', 'ProductType', 'ProductSubtype', 'ProductSolution']; //this must sync up with availableFilters in the Model in Laravel.
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Product',
                    value: 'Product',
                    align: "left"
                }, {
                    text: 'Carrier',
                    value: 'carrier.CarrierName',
                    align: "left"
                }, {
                    text: 'Solution',
                    value: 'ProductSolution',
                    align: "left"
                }, {
                    text: 'Type',
                    value: 'ProductType',
                    align: "left"
                }, {
                    text: 'Sub Type',
                    value: 'ProductSubtype',
                    align: "left"
                }, {
                    text: 'Codes',
                    value: 'ProductCodes',
                    align: "left"
                }, {
                    text: 'eApp',
                    value: 'eAppInd',
                    align: "left"
                }, {
                    text: 'Paper App',
                    value: 'PaperAppInd',
                    align: "left"
                }, {
                    text: 'Instant Decision',
                    value: 'InstantDecisionInd',
                    align: "left"
                }, {
                    text: 'Flexible Premium Possible',
                    value: 'FlexiblePremiumPossibleInd',
                    align: "left"
                }, {
                    text: 'NY',
                    value: 'NY',
                    align: "left"
                }, {
                    text: 'CA',
                    value: 'CA',
                    align: "left"
                }, {
                    text: 'Email',
                    value: 'Email',
                    align: "left"
                }, {
                    text: 'Fax',
                    value: 'Fax',
                    align: "left"
                }, {
                    text: 'Product Guide',
                    value: 'ProductGuide',
                    align: "center"
                }, {
                    text: '',
                    value: 'ID',
                },

            ]
        },
        available_filters: function() {
            return this.available_product_filters;
        },
        division_filter_count: function() {
            let div_filter_count = 0
            this.user.all_divisions.forEach(div => {
                if (div.ActiveInd == '1')
                    div_filter_count++
                if (div.HiddenInd == '1')
                    div_filter_count++
            })
            return div_filter_count
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            opt.itemsPerPage = 'all'
            if (this.the_data.length > 0)
                return

            var g = this
            this.data_loading = true
            QuilityAPI.getProducts(this.filters, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.meta.total
                if (typeof json.meta.filters != 'undefined') {
                    g.$set(g, 'available_product_filters', json.meta.filters)
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        viewProduct: function(item) {
            if (this.hasPermission('manage:Carriers')) {
                this.$router.push({ name: 'ProductDetail', params: { product_id: item.ID } })
            }
        },
        exportResults: function() {
            this.downloadProductsCsv(this.all_data)
        },
        downloadProductsCsv: function(products) {
            const lines = []

            //array of data table fields for csv header row
            const fields = this.the_headers.map(header => {
                return header.text != '' ? header.text : "ID"
            })

            //add the division active/hidden indicator field headings
            this.user.all_divisions.forEach(division => {
                fields.push(division.Division + " Active", division.Division + " Hidden")
            })

            //build the string and add to lines array
            lines.push(`"` + fields.join(`","`) + `"`)

            //loop through product records and build the csv text line
            products.map(product => {
                //array of product field values based on fields defined by data table
                let values = this.the_headers.map(header => {
                    if (header.value === "ProductCodes") {
                        return this.listProductCodes(product)
                    } else if (header.text === "Carrier") {
                        return product.carrier.CarrierName
                    } else {
                        return product[header.value]
                    }
                })

                //add the division active/hidden values
                this.user.all_divisions.forEach(division => {
                    const product_division = product.divisions.find(p_division => {
                        return p_division.DivisionID == division.ID
                    })
                    if (product_division)
                        values.push(product_division.ActiveInd, product_division.HiddenInd)
                    else
                        values.push(0, 0)
                })

                //build the string and add to lines array
                lines.push(`"` + values.join(`","`) + `"`)
            })

            //build all rows of csv by joining lines array
            let txt = lines.join("\n")

            //generate the download
            var element = document.createElement('a')
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt))
            element.setAttribute('download', "Products.csv")
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)

        },
        listProductCodes(item) {
            let codes = item.product_code
            if (codes === null || codes === undefined || codes.length === 0)
                return ''
            let arr = codes.map(code => code.ProductCode)
            return arr.join(", ")
        },
        filterProductsByDivisionFilters(products) {

            let selections = []
            this.user.all_divisions.forEach(division => {
                if (division.ActiveInd == '1')
                    selections.push(`${division.ID}ActiveInd`)
                if (division.HiddenInd == '1')
                    selections.push(`${division.ID}HiddenInd`)
            })

            //if no filters selected, return all products
            if (selections.length == 0)
                return products

            //filter carriers by its division filters
            let filtered_products = products.filter(product => {
                var include = false
                product.divisions.forEach(division => {
                    if (division.ActiveInd == '1' && selections.includes(`${division.DivisionID}ActiveInd`)) {
                        include = true
                    }
                    if (division.HiddenInd == '1' && selections.includes(`${division.DivisionID}HiddenInd`)) {
                        include = true
                    }
                })
                return include
            })
            return filtered_products
        }
    },
    components: {
        QExpandableDataTable
    },
    activated() {
        if (localStorage.getItem("refreshProducts") == "true") {
            localStorage.setItem("refreshProducts", false)
            this.refreshData()
        }
    }
}

</script>
<style scoped>
div>>>td,
div>>>th {
    white-space: nowrap;
    cursor: pointer;
}

</style>
